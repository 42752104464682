@import "../../style-assets/typography";

.side-menu-wrapper {
	min-width             : 75px;
	width                 : 75px;
	height                : 100%;
	// z-index            : 999;
	// background-color: red;
	// position: fixed;
	position              : sticky;
	top                   : 0;
	z-index               : 5;
	transition            : 100ms;

	.side-menu-open-arrow {
		transform : rotate(180deg);
		transition: transform 0.5s;
	}

	.side-menu-collapsed-arrow {
		transform: rotate(0deg);
	}

	.side-menu {
		// width                        : 257px;

		width                 : 100%;
		background-color      : white;
		height                : 100%;
		// border-radius   : 0 6px 6px 0;
		box-shadow            : 0px 4px 24px rgba(135, 135, 135, 0.06);
		padding               : 25px 16px 20px 16px;
		// overflow           : hidden;
		position              : relative;
		border-radius         : 24px;



		.side-menu-logo {
			display             : flex;
			flex-direction      : row;
			align-items         : center;
			// gap           : 35px;
			// margin-left      : 16px;
			margin-bottom       : 48px;

			img {
				height: 32px;
				width : 32px;
			}

			span {
				color      : $white;
				margin-left: 12px;
			}

			.disc-icon {
				width : 24px;
				height: 24px;
			}
		}

		.disable {
			opacity: 30%;
		}

		.side-menu-item {
			text-decoration      : none;
			width                : 100%;
			height               : 44px;
			display              : flex;
			align-items          : center;
			justify-content      : space-between;
			// background-color  : red;
			// border-radius     : 50px;
			gap                  : 8px;
			padding              : 15px 25px 15px 19px;
			// border-left    : 8px solid transparent;

			span {
				@extend .body-3;
				color      : #808191 !important;
				font-weight: 500 !important;

			}

			.menu-item-icon {
				background-color: $white;
				font-weight     : 400 !important;
			}
		}

		.sub-menu-item {
			text-decoration   : none;
			width             : 100%;
			height            : 44px;
			border-radius     : 50px;
			display           : flex;
			align-items       : center;
			justify-content   : space-between;
			padding           : 15px 25px 15px 19px;
			// border-left    : 8px solid transparent;
			margin-top        : 8px !important;

			span {
				@extend .body-3;
				color      : #808191 !important;
				font-weight: 500 !important;

			}
		}

		.sub-item-selected,
		.sub-menu-item-hover-effect:hover {
			background         : #121216;
			// border-left     : 8px solid #00D69A;
			// border-bottom: 1px solid transparent;

			span {
				@extend .body-3;
				color      : $white !important;
				font-weight: 500 !important;

			}

			svg {
				// fill: #FFFFFF !important;

				path {
					stroke: #FFFFFF !important;
				}
			}
		}

		.side-menu-selected,
		.menu-item-hover-effect:hover {
			background    : #121216;
			// border-left: 8px solid #00D69A;
			padding       : 15px 25px 15px 19px;
			border-radius : 50px;

			span {
				@extend .body-3;
				color      : $white !important;
				font-weight: 500 !important;

			}

			svg {
				// fill: #FFFFFF !important;

				path {
					stroke: #FFFFFF !important;
				}
			}

			.menu-item-icon {
				background-color: $white;
				font-weight     : 400 !important;
			}
		}

		.notification-container {
			width        : auto;
			height       : 50px;
			align-items  : center;
			padding      : 8px 0px;
			box-sizing   : border-box;
			border-bottom: 1px solid rgb(87, 76, 127);

			.notification {
				display       : flex;
				flex-direction: row;
				align-items   : center;
				padding       : 0px 20px;
				margin-left   : 12px;
				width         : 270px;
				height        : 40px;
				cursor        : pointer;

				.notification-bell-icon {
					width      : 24px;
					height     : 24px;
					margin-left: -6px;
				}
			}
		}

		.notification-container:hover {
			background    : $dark-purple;
			// border-left: 8px solid #00D69A;

			.notification {
				margin-left: 5px
			}

			span {
				@extend .body-3;
				color      : $white !important;
				font-weight: 500 !important;
			}
		}

		.side-menu-footer {
			border-top: 1px solid rgba(255, 255, 255, 0.15);
			gap       : 12px;

			.support-container {
				width        : 270px;
				height       : 56px;
				padding      : 10px 0px;
				box-sizing   : border-box;
				border-bottom: 1px solid;
				border-color : #E1E1E1;

				.support {
					display       : flex;
					flex-direction: row;
					align-items   : center;
					padding       : 20px 20px;
					margin-left   : 12px;
					width         : 270px;
					height        : 40px;
					cursor        : pointer;

					.support-icon {
						width       : 22px;
						height      : 22px;
						margin-right: 25px;
					}

					.body-3 {
						line-height: 17px;
						color      : #56585A;
					}
				}
			}

			.support-container:hover {
				background-color: #DEDAF2;
				border-left     : 4px solid $primary-brand;

				.body-3 {
					color: $primary-brand;
				}
			}

			.side-menu-avatar {
				width           : 100%;
				// height       : 52px;
				align-items     : center;
				box-sizing      : border-box;
				border-bottom   : 1px solid rgba(255, 255, 255, 0.15);
				padding         : 16px 16px 16px 20px;

				.side-menu-avatar-logo {
					height         : 35px;
					width          : 100%;
					align-items    : center;
					justify-content: space-between !important;
					cursor         : pointer;

					.avatar {
						width        : 33.61px;
						height       : 32px;
						border-radius: 6px;
						margin-right : 21px;
					}

					.side-menu-avatar-logo-text {
						align-items: flex-start;
						width      : calc(100% - 77px);

						.body-3 {
							color: #121216;
						}

						.body-4 {
							color: $black-400;
						}
					}
				}
			}

			.side-menu-avatar:hover {
				background-color   : #121216;
				border-radius      : 50px;
				// border-left     : 8px solid #00D69A;

				.side-menu-avatar-logo-text {
					.body-3 {
						color: $white;
					}
				}
			}
		}
	}
}

.side-menu-open {
	min-width: 257px !important;
	width    : 257px !important;

	.side-menu-logo {
		margin-left: 16px;
	}
}

.side-menu-collapsed {
	position: relative;


	.side-menu {
		width                     : 100%;
		transition                : 50ms;
		transition-timing-function: linear;


		.side-bar-menu-icons {
			display: none !important;
		}

		.side-menu-footer {
			.side-menu-avatar {
				padding-left: 0px;
				margin-right: 25px;

				.side-menu-avatar-arrow,
				.side-menu-avatar-logo-text {
					display: none !important;
				}
			}
		}
	}

	.side-menu-logo {
		span {
			display: none;
		}
	}

	.side-menu-item {
		padding        : 0px !important;
		display        : flex;
		align-items    : center !important;
		justify-content: center !important;
		gap            : 24px !important;
		height         : 44px;

		.menu-item-text,
		.plus-icon {
			display: none;
		}



	}

	.sub-menu-item {
		padding        : 0 !important;
		height         : 44px;
		display        : flex;
		align-items    : center !important;
		justify-content: center !important;
		width          : 100% !important;

		.menu-item-text,
		.plus-icon {
			display: none;
		}
	}

	.menu-item-icon {
		border: 1px solid !important;
	}

	.side-menu-selected,
	.menu-item-hover-effect:hover {
		width           : 100% !important;
		padding         : 0px !important;
		background-color: rgb(0, 0, 0) !important;
		border-radius   : 50px !important;
		height          : 44px !important;
		display         : flex;
		justify-content : center !important;

		span {
			@extend .body-3;
			color      : $white !important;
			font-weight: 500 !important;

		}

		.menu-item-icon {
			background-color: $white;
			font-weight     : 400 !important;
			border          : 1px solid !important;
		}
	}

	.notification-container {
		span {
			display: none;
		}
	}
}

.side-menu-collapsed:hover {
	min-width: 257px !important;
	width    : 257px !important;

	position: relative;

	.side-menu-collapsed-arrow {
		transform: rotate(180deg) !important;
	}


	.side-menu {
		width                     : 100%;
		transition                : 50ms;
		transition-timing-function: linear;

		.side-menu-footer {
			.side-menu-avatar {
				padding-left: 0px;
				margin-right: 25px;

				.side-menu-avatar-arrow,
				.side-menu-avatar-logo-text {
					display: flex !important;
				}
			}
		}
	}

	.side-menu-logo {
		span {
			display: flex;
		}
	}

	.side-menu-item {
		display           : flex;
		align-items       : center !important;
		justify-content   : space-between !important;
		padding           : 15px 25px 15px 19px !important;
		// border-left    : 8px solid transparent;
		border-radius     : 24px;

		.menu-item-text,
		.plus-icon {
			display: flex;
		}

	}

	.sub-menu-item {

		display           : flex;
		align-items       : center !important;
		justify-content   : space-between !important;
		padding           : 0px 25px 0px 19px !important;
		// border-left    : 8px solid transparent;

		.menu-item-text,
		.plus-icon {
			display: flex;
		}
	}

	.side-menu-selected,
	.menu-item-hover-effect:hover {
		background: #121216;
		padding   : 15px 25px 15px 19px;

		span {
			@extend .body-3;
			color      : $white !important;
			font-weight: 500 !important;

		}

		.menu-item-icon {
			background-color: $white;
			font-weight     : 400 !important;
		}
	}

	.sub-item-selected,
	.sub-menu-item-hover-effect:hover {
		background      : #121216;
		height          : 44px !important;
		width           : 100% !important;
		// border-bottom: 1px solid transparent;

		span {
			@extend .body-3;
			color      : $white !important;
			font-weight: 500 !important;

		}
	}

	.notification-container {
		span {
			display: flex;
		}
	}

	.side-menu-avatar {
		width           : 100%;
		// height       : 52px;
		align-items     : center;
		box-sizing      : border-box;
		border-bottom   : 1px solid rgba(255, 255, 255, 0.15);
		padding         : 16px 16px 16px 20px !important;
		border-left     : 8px solid transparent;
	}

}

.side-menu-avatar:hover .side-menu-logo {
	span {
		display: block;
	}
}

.side-menu-collapsed:hover .side-bar-menu-icons {
	display: block !important;
}

@media screen and (max-width: 767px) {
	.side-menu-wrapper {
		width           : 100%;
		height          : 56px;
		position        : sticky;
		top             : 0;
		background-color: #FFFFFF;
		z-index         : 999;
		border-radius   : 50px;

		.side-menu:hover {
			width: 100%;
		}

		.side-menu {
			display        : flex;
			display        : flex;
			flex-direction : row;
			align-items    : center;
			justify-content: flex-start;
			padding        : 20px 16px;

			.menu-collapse {
				position: absolute;
				left    : 16px;

				svg {
					height: 32px;
				}

				.menu-icon:hover {
					cursor: pointer;
				}

			}

			.side-menu-logo {
				width : auto;
				height: 32px;
				margin: auto;
			}
		}

		.disable {
			opacity: 30%;
		}

		.menu-open {
			position                     : absolute;
			height                       : 100vh;
			top                          : 0;
			left                         : 0;
			width                        : 100%;
			transition                   : 50ms;
			transition-timing-function   : linear;
			// background                : rgba(0, 230, 122, 0.8);
			z-index                      : 999;
			overflow-y                   : scroll;
			border-radius                : 24px;

			.menu {
				width        : 100%;
				height       : 100vh;
				background   : rgb(255, 255, 255);
				box-shadow   : 0px 4px 24px rgba(135, 135, 135, 0.06);
				border-radius: 0px 6px 6px 0px;
				padding      : 0px 0px 30px;

				.menu-header {
					display        : flex;
					align-items    : center;
					justify-content: center;

					svg {
						top   : 28px;
						left  : 16px;
						height: 32px;
					}
				}

				.menu-items {
					height            : calc(100vh - 100px);
					// margin-top     : 60px;
					display           : flex;
					flex-direction    : column;
					justify-content   : space-between;
					gap               : 24px;
					padding           : 24px;


					.side-menu-item {
						display           : flex;
						align-items       : center !important;
						justify-content   : space-between !important;
						padding           : 15px 25px 15px 19px !important;
						// border-left    : 8px solid transparent;
						border-radius     : 24px;

						.menu-item-text,
						.plus-icon {
							display: flex;
						}

					}

					.sub-menu-item {

						display           : flex;
						align-items       : center !important;
						justify-content   : space-between !important;
						padding           : 0px 25px 0px 19px !important;
						// border-left    : 8px solid transparent;
						height            : 44px;
						border-radius     : 24px;

						.menu-item-text,
						.plus-icon {
							display: flex;
						}
					}

					.side-menu-selected,
					.menu-item-hover-effect:hover {
						background   : #121216;
						height       : 44px !important;
						width        : 100% !important;
						border-radius: 24px;

						span {
							// @extend .body-3;
							color      : $white !important;
							font-weight: 500 !important;

						}

						.menu-item-icon {
							background-color: $white;
							font-weight     : 400 !important;
						}
					}

					.sub-item-selected,
					.sub-menu-item-hover-effect:hover {
						background      : #121216;
						height          : 44px !important;
						width           : 100% !important;
						border-radius   : 24px;
						// border-bottom: 1px solid transparent;

						span {
							// @extend .body-3;
							color      : $white !important;
							font-weight: 500 !important;

						}
					}

					.side-menu-footer {
						// border-top: 1px solid rgba(255, 255, 255, 0.15);
						gap: 12px;

						.side-menu-avatar {
							width                 : 100%;
							// height       : 52px;
							align-items           : center;
							box-sizing            : border-box;
							// border-bottom      : 1px solid rgba(255, 255, 255, 0.15);
							padding               : 16px 16px 16px 20px;
							// border-left     : 8px solid transparent;

							.side-menu-avatar-logo {
								height         : 35px;
								width          : 100%;
								align-items    : center;
								justify-content: space-between !important;
								cursor         : pointer;

								.avatar {
									width        : 33.61px;
									height       : 32px;
									border-radius: 6px;
									margin-right : 21px;
								}

								// .side-menu-avatar-logo-text {
								// 	align-items: flex-start;
								// 	width      : calc(100% - 77px);

								// 	.body-3 {
								// 		color: $white;
								// 	}

								// 	.body-4 {
								// 		color: $black-400;
								// 	}
								// }
							}
						}

						.side-menu-avatar:hover {
							background-color   : $dark-100;
							border-radius      : 50px;
							// border-left     : 8px solid #00D69A;


							.side-menu-avatar-logo-text {
								.body-3 {
									color: $white;
								}
							}
						}
					}
				}
			}
		}

		.menu-open::-webkit-scrollbar {
			display: none;
		}

		.menu-close {
			width   : 0px !important;
			left    : -32px !important;
			overflow: hidden;

		}


	}
}

@media screen and (max-width: 400px) {
	.side-menu-wrapper {

		.menu-open {
			position                     : absolute;
			height                       : 100vh;
			top                          : 0;
			left                         : 0;
			width                        : 100%;
			transition                   : 50ms;
			transition-timing-function   : linear;
			background                : rgb(255, 255, 255);
			z-index                      : 999;
			overflow-y                   : scroll;
			border-radius                : 24px;


		}



	}
}

@media screen and (max-width: 370px) {
	.side-menu-wrapper {

		.menu-open {
			position                     : absolute;
			height                       : 100vh;
			top                          : 0;
			left                         : 0;
			width                        : 94%;
			transition                   : 50ms;
			transition-timing-function   : linear;
			// background                : rgba(0, 230, 122, 0.8);
			z-index                      : 999;
			overflow-y                   : scroll;
			border-radius                : 24px;


		}



	}
}